import { reactive } from "vue";
import { getApiList, getApiRegion, getApiTrend, getApiTop } from "@/request/api";
import { ElLoading } from 'element-plus';

export default (indexState, getList, getWeightConfig) => {
    const state = reactive({
        // 菜品评分信息
        tableFisrt: {
            query: {
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            tableData: [],
        },
        // 菜品地域分布
        tableSecond: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 菜品季度趋势
        tableThird: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 季度Top菜品
        tableFourth: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
    })

    // maxSize计算
    function maxSizeComplate(params) {
        if (indexState.menuActive !== 1 || indexState.query.province.length > 0) {
            params["maxSize"] = 300;
        }
        return params;
    }

    // 获取列表-菜品评分信息
    async function getListFirst() {
        let params = { ...indexState.query, ...state.tableFisrt.query, ...getWeightConfig() };
        params = maxSizeComplate(params)
        if (
            indexState.menuActive === 2 ||
            indexState.menuActive === 3 ||
            indexState.menuActive === 4
        ) {
            params["sortField"] = "total_score";
        }
        if (indexState.menuActive === 5) {
            params["sortField"] = "dish_recommends_num";
        }
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getApiList(params);
        state.tableFisrt.total = data?.total || 0;
        state.tableFisrt.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-菜品地域分布
    async function getListSecond() {
        const params = { ...indexState.query, ...state.tableSecond.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getApiRegion(params);
        state.tableSecond.total = data?.total || 0;
        state.tableSecond.titleData = data?.title || [];
        state.tableSecond.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-菜品季度趋势
    async function getListThird() {
        const params = { ...indexState.query, ...state.tableThird.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getApiTrend(params);
        state.tableThird.total = data?.total || 0;
        state.tableThird.titleData = data?.title || [];
        state.tableThird.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-季度Top菜品
    async function getListFourth() {
        const params = { ...indexState.query, ...state.tableFourth.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getApiTop(params);
        state.tableFourth.total = data?.total || 0;
        state.tableFourth.titleData = data?.title || [];
        state.tableFourth.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 分页
    function pageChange() {
        getList();
    }

    // 搜索
    function search() {
        switch (indexState.tableActive) {
            case 'first':
                state.tableFisrt.query.pageNum = 1;
                state.tableFisrt.total = 0;
                state.tableFisrt.tableData = [];
                break;
            case 'second':
                state.tableSecond.query.pageNum = 1;
                state.tableSecond.total = 0;
                state.tableSecond.tableData = [];
                break;
            case 'third':
                state.tableThird.query.pageNum = 1;
                state.tableThird.total = 0;
                state.tableThird.tableData = [];
                break;
            case 'fourth':
                state.tableFourth.query.pageNum = 1;
                state.tableFourth.total = 0;
                state.tableFourth.tableData = [];
                break;
        }
        getList();
    }

    // 清空所有table/页码重置
    function clearTable() {
        state.tableFisrt.query.pageNum = 1;
        state.tableFisrt.total = 0;
        state.tableFisrt.tableData = [];
        state.tableSecond.query.pageNum = 1;
        state.tableSecond.total = 0;
        state.tableSecond.tableData = [];
        state.tableThird.query.pageNum = 1;
        state.tableThird.total = 0;
        state.tableThird.tableData = [];
        state.tableFourth.query.pageNum = 1;
        state.tableFourth.total = 0;
        state.tableFourth.tableData = [];
    }
    return { state, search, clearTable, getListFirst, getListSecond, getListThird, getListFourth, pageChange }
}