import { onBeforeMount, reactive } from "vue";
import { getApiDict } from '@/request/api';
import { ElLoading } from 'element-plus';

// 参数选项
export default function paramsListHook({ loading, query }) {
    const paramsListState = reactive({
        batchList: [],// 批次
        provinceList: [],// 省份
        cityList: [],// 城市
        ratioTypeList: ['同比', '环比'],// 比率
        cuisineList: [],// 餐饮类型-菜系1级
        cuisine1List: [],// 菜系类型-菜系2级
        cuisineTypeList: [],// 菜系-菜系3级
        materialList: [],// 原料大类
        material1List: [],// 原料二级
        material2List: [],// 原料3级
        flavorList: [],//风味1
        flavor1List: [],//风味2
        processList: [],//制作工艺
        styleTypeList: []// 奉客形式
    })
    // 获取批次
    async function getBatch() {
        const loadingInstance = ElLoading.service()
        const { data } = await getApiDict({ code: 'batch' })
        paramsListState.batchList = data || [];
        query.batch = paramsListState.batchList[0];
        setTimeout(() => { loadingInstance.close() }, 200)
    }
    // 获取省份
    async function getProvince() {
        loading = true;
        const { data } = await getApiDict({ code: 'province' })
        paramsListState.provinceList = data || [];
        loading = false;
    }
    // 获取城市
    async function getCity() {
        loading = true;
        const { data } = await getApiDict({ code: 'city', province: query.province })
        paramsListState.cityList = data || [];
        loading = false;
    }
    // 获取餐饮类型-菜系一级
    async function getCuisine() {
        const { data } = await getApiDict({ code: 'cuisine' })
        paramsListState.cuisineList = data || []
    }
    // 获取菜系类型-菜系二级
    async function getCuisine1() {
        const { data } = await getApiDict({ code: 'cuisine1', cuisine1: query.mainType })
        paramsListState.cuisine1List = data || []
    }
    // 获取菜系-菜系三级
    async function getCuisine2() {
        const params = { code: 'cuisine2', cuisine1: query.mainType, cuisine2: query.cuisineType }
        const { data } = await getApiDict(params)
        paramsListState.cuisineTypeList = data || []
    }
    // 获取原料大类
    async function getMaterial() {
        const { data } = await getApiDict({ code: 'material' })
        paramsListState.materialList = data || []
    }
    // 获取原料二级类型
    async function getMaterial1() {
        const { data } = await getApiDict({ code: 'material1', material1: query.materialMainType })
        paramsListState.material1List = data || []
    }
    // 获取原料3级
    async function getMaterial2() {
        const { data } = await getApiDict({ code: 'material2', material1: query.materialMainType, material2: query.materialType })
        paramsListState.material2List = data || []
    }
    // 获取风味大类
    async function getFlavor() {
        const { data } = await getApiDict({ code: 'flavor' })
        paramsListState.flavorList = data || []
    }
    // 获取风味2级
    async function getFlavor1() {
        const { data } = await getApiDict({ code: 'flavor1', flavor: query.flavorMainType })
        paramsListState.flavor1List = data || []
    }
    // 获取制作工艺
    async function getProcess() {
        const { data } = await getApiDict({ code: 'process' })
        paramsListState.processList = data || []
    }
    // 获取奉客形式
    async function getStyleType() {
        const { data } = await getApiDict({ code: 'style' })
        paramsListState.styleTypeList = data || [];
    }
    // 省份改变
    function changeProvince() {
        query.city = [];
        getCity();
    }
    // 餐饮类型改变
    function changeMainType() {
        query.cuisineType = [];//清空菜系类型
        query.cuisine = [];//清空菜系已选
        getCuisine1();//获取菜系
        getCuisine2();//获取菜系明细
    }
    // 菜系类型改变
    function changeCuisineType() {
        query.cuisine = [];//清空菜系已选
        getCuisine2();//获取菜系明细
    }
    // 原料大类改变
    function changeMaterialMainType() {
        query.materialType = [];//原料2级
        query.materialSubType = [];//原料3级
        getMaterial1();//原料2级
        getMaterial2();//原料3级
    }
    // 原料2级改变
    function changeMaterialType() {
        query.materialSubType = [];//原料3级
        getMaterial2();//原料3级
    }
    // 风味1级改变
    function changeflavor() {
        query.flavorType = [];//风味2级
        getFlavor1();//风味2级
    }

    onBeforeMount(() => {
        getBatch();
        getProvince();
        getCity()
        getCuisine()// 餐饮类型-菜系1级
        getCuisine1();// 餐饮类型-菜系2级
        getCuisine2();// 餐饮类型-菜系3级
        getMaterial();//原料大类
        getMaterial1();//原料二级
        getMaterial2();//原料3级
        getFlavor();//风味1级
        getFlavor1();//风味2级
        getProcess();//制作工艺
        getStyleType();// 奉客形式
    })

    return { paramsListState, changeMainType, changeCuisineType, changeProvince, changeMaterialMainType, changeMaterialType, changeflavor }
}