export default function menuChangeHook(state) {
    // 菜单改变
    function menuChange(id) {
        state.menuActive = id; // 改变当前选中菜单
        // 重置条件
        // 数据范围筛选
        state.query.province = [];//省份
        state.query.city = [];//城市
        state.query.ratioType = "环比";//比率
        state.query.mainType = [];//餐饮类型
        state.query.cuisineType = [];//菜系类型
        state.query.cuisine = [];//菜系
        state.query.materialMainType = [];//原料大类
        state.query.materialType = [];//原料二级类型
        state.query.materialSubType = [];//原料细类
        state.query.flavorMainType = [];//风味大类
        state.query.flavorType = [];//风味二级类型
        state.query.processMainType = [];//制作工艺
        state.query.styleType = [];//奉客形式
        state.query.shop_num_min = null; //品牌门店数
        state.query.shop_num_max = null;
        //计算结果筛选
        state.query.brand_num_min = null; //品牌数
        state.query.brand_num_max = null;
        state.query.province_num_min = null; //省份数
        state.query.province_num_max = null;
        state.query.city_num_min = null; //城市数
        state.query.city_num_max = null;
        state.query.brand_ratio_min = null; //品牌增长率
        state.query.brand_ratio_max = null;
        state.query.hot_score_min = null; //热度得分
        state.query.hot_score_max = null;
        state.query.dish_recommeds_rel_ratio_min = null; //总推荐数增长率
        state.query.dish_recommeds_rel_ratio_max = null;
        state.query.avg_recommeds_rel_ratio_min = null; //店均推荐数增长率
        state.query.avg_recommeds_rel_ratio_max = null;
        state.query.dish_recommends_num_min = null; //总推荐数
        state.query.dish_recommends_num_max = null;
        state.query.avg_recommends_num_min = null; //店均推荐数
        state.query.avg_recommends_num_max = null;
        state.query.city_ratio_min = null; //城市增长率
        state.query.city_ratio_max = null;
        // 设置默认条件-完全创新型（单店单菜）
        switch (state.menuActive) {
            case 2://完全创新型（单店单菜）
                state.query.shop_num_max = 5;//品牌门店数<=5
                state.query.city_num_max = 2;//城市数<=2 
                state.query.avg_recommeds_rel_ratio_min = 20;//店均推荐数增长率>=20%
                break;
            case 3:// 完全创新型（连锁品牌）
                state.query.shop_num_min = 6;//品牌门店数>55
                state.query.brand_num_max = 2;//品牌数<=2
                state.query.avg_recommeds_rel_ratio_min = 20;//店均推荐数增长率>=20%
                break;
            case 4://区域创新型
                state.query.brand_num_min = 3;//品牌数>2
                state.query.province_num_max = 3; //省份数<=3
                state.query.city_num_min = 3; // 城市数>2
                state.query.hot_score_min = 15; //热度总得分>=15
                state.query.avg_recommeds_rel_ratio_min = 20;//店均推荐数增长率>=20%
                break;
            case 5://老品爆发型
                state.query.province_num_min = 5; //省份数>=5
                state.query.city_num_min = 15; //城市数>=15
                state.query.dish_recommeds_rel_ratio_min = 20; //总推荐数增长率>=20%
                break;
        }
    }
    return {
        menuChange
    }
}