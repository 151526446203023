<template>
  <div class="home" v-loading="state.loading">
    <!-- 头部 -->
    <my-header @outLoginFun='outLoginFun' :menuList="state.menuList" v-model:menuActive='state.menuActive'
      @change='menuChange' @weightOpen="weightOpen"></my-header>
    <!-- 数据范围筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="数据范围筛选"></small-title>
      </div>
      <div class="search_box">
        <div class="li">
          <h6>批次：</h6>
          <el-select v-model="state.query.batch" class="m-2" placeholder="全部" filterable>
            <el-option v-for="item in paramsListState.batchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>省份：</h6>
          <el-select v-model="state.query.province" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeProvince">
            <el-option v-for="item in paramsListState.provinceList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>城市：</h6>
          <el-select v-model="state.query.city" class="m-2" placeholder="全部" filterable clearable multiple collapse-tags>
            <el-option v-for="item in paramsListState.cityList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>比率：</h6>
          <el-select v-model="state.query.ratioType" class="m-2" placeholder="全部">
            <el-option v-for="item in paramsListState.ratioTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>餐饮类型：</h6>
          <el-select v-model="state.query.mainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeMainType">
            <el-option v-for="item in paramsListState.cuisineList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系类型：</h6>
          <el-select v-model="state.query.cuisineType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeCuisineType">
            <el-option v-for="item in paramsListState.cuisine1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系：</h6>
          <el-select v-model="state.query.cuisine" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.cuisineTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料大类：</h6>
          <el-select v-model="state.query.materialMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeMaterialMainType">
            <el-option v-for="item in paramsListState.materialList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料二级类型：</h6>
          <el-select v-model="state.query.materialType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeMaterialType">
            <el-option v-for="item in paramsListState.material1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料细类：</h6>
          <el-select v-model="state.query.materialSubType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.material2List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>风味大类：</h6>
          <el-select v-model="state.query.flavorMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeflavor">
            <el-option v-for="item in paramsListState.flavorList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>风味二级类型：</h6>
          <el-select v-model="state.query.flavorType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.flavor1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>制作工艺：</h6>
          <el-select v-model="state.query.processMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.processList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>奉客形式：</h6>
          <el-select v-model="state.query.styleType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.styleTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 2 && state.menuActive !== 3"> -->
        <div class="li">
          <h6>品牌门店数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.shop_num_min"></el-input>-
            <el-input type="number" v-model="state.query.shop_num_max"></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 计算结果筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="计算结果筛选"></small-title>
      </div>
      <div class="search_box">
        <!-- <div class="li" v-show="state.menuActive !== 3 && state.menuActive !== 4"> -->
        <div class="li">
          <h6>品牌数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.brand_num_min"></el-input>-
            <el-input type="number" v-model="state.query.brand_num_max"></el-input>
          </div>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 4 && state.menuActive !== 5"> -->
        <div class="li">
          <h6>省份数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.province_num_min"></el-input>-
            <el-input type="number" v-model="state.query.province_num_max"></el-input>
          </div>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 2 && state.menuActive !== 4 && state.menuActive !== 5"> -->
        <div class="li">
          <h6>城市数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_num_min"></el-input>-
            <el-input type="number" v-model="state.query.city_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>品牌增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.brand_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.brand_ratio_max"></el-input>
          </div>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 4"> -->
        <div class="li">
          <h6>热度得分：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.hot_score_min"></el-input>-
            <el-input type="number" v-model="state.query.hot_score_max"></el-input>
          </div>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 5"> -->
        <div class="li">
          <h6>总推荐数增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.dish_recommeds_rel_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.dish_recommeds_rel_ratio_max"></el-input>
          </div>
        </div>
        <!-- <div class="li" v-show="state.menuActive !== 2 && state.menuActive !== 3 && state.menuActive !== 4"> -->
        <div class="li">
          <h6>店均推荐数增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.avg_recommeds_rel_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.avg_recommeds_rel_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>总推荐数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.dish_recommends_num_min"></el-input>-
            <el-input type="number" v-model="state.query.dish_recommends_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>店均推荐数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.avg_recommends_num_min"></el-input>-
            <el-input type="number" v-model="state.query.avg_recommends_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>省份增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.prov_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.prov_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>城市增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.city_ratio_max"></el-input>
          </div>
        </div>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="resetFunc">重置</el-button>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="星探结果"></small-title>
        <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
            <Right />
          </el-icon>
        </el-button>
      </div>
      <el-tabs v-model="state.tableActive" class="demo-tabs">
        <!-- 菜品评分信息 -->
        <el-tab-pane label="菜品评分信息" name="first">
          <div class="table_box">
            <el-table :data="tableState.tableFisrt.tableData" stripe border style="width: 100%"
              :header-cell-style="headerCellStyleHandle" :cell-style="cellStyleHandle" height="600">
              <el-table-column prop="dish_name" label="菜品名称" min-width="180" show-overflow-tooltip fixed="left" />
              <el-table-column prop="total_score" label="总得分" show-overflow-tooltip fixed="left" />
              <el-table-column prop="shop_num_score" label="门店数得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="brand_num_score" label="品牌数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="province_num_score" label="省份数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="city_num_score" label="城市数得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="region_score" label="集中性总得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="prov_ratio_score" label="省份增长率得分" show-overflow-tooltip min-width="110"
                align="center" />
              <el-table-column prop="city_ratio_score" label="城市增长率得分" show-overflow-tooltip min-width="110"
                align="center" />
              <el-table-column prop="recommends_rel_ratio_score" label="总推荐数增长率得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="avg_recommends_rel_ratio_score" label="店均推荐数增长率得分" show-overflow-tooltip
                min-width="100" align="center" />
              <el-table-column prop="growth_score" label="成长性总得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="dish_recommends_num_score" label="总推荐数得分" show-overflow-tooltip min-width="115"
                align="center" />
              <el-table-column prop="avg_recommends_num_score" label="店均推荐数得分" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="hot_score" label="热度总得分" show-overflow-tooltip min-width="100" align="center" />
              <!-- <el-table-column prop="shop_num" label="门店数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="brand_num" label="品牌数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="brand_ratio" label="品牌增长率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="prov_num" label="省份数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="city_num" label="城市数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="city_ratio" label="城市增长率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="recommends_ratio" label="总推荐数增长率" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="avg_recommends_ratio" label="店均推荐数增长率" show-overflow-tooltip min-width="140"
                align="center" />
              <el-table-column prop="dish_recommends_num" label="总推荐数" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="dish_recommends_num_rank" label="总推荐数排名" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="avg_recommends_num" label="店均推荐数" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="avg_recommends_num_rank" label="店均推荐数排名" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="dish_id" label="菜品ID" show-overflow-tooltip min-width="80" align="center" />
              <el-table-column prop="batch" label="批次" show-overflow-tooltip min-width="80" align="center" /> -->
            </el-table>
            <div class="no_data" v-if="tableState.tableFisrt.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableFisrt.total"
                :page-size="tableState.tableFisrt.query.pageSize"
                v-model:current-page="tableState.tableFisrt.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 菜品地域分布 -->
        <el-tab-pane label="菜品地域分布" name="second">
          <div class="table_box">
            <el-table :data="tableState.tableSecond.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="dish_name" label="菜品名称" show-overflow-tooltip />
              <el-table-column v-for="item in tableState.tableSecond.titleData" :key="item" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableSecond.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableSecond.total"
                :page-size="tableState.tableSecond.query.pageSize"
                v-model:current-page="tableState.tableSecond.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 菜品季度趋势 -->
        <el-tab-pane label="菜品季度趋势" name="third">
          <div class="table_box">
            <el-table :data="tableState.tableThird.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="dish_name" label="菜品名称" show-overflow-tooltip />
              <el-table-column v-for="item in tableState.tableThird.titleData" :key="item" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableThird.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableThird.total"
                :page-size="tableState.tableThird.query.pageSize"
                v-model:current-page="tableState.tableThird.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 季度Top菜品 -->
        <el-tab-pane label="季度Top菜品" name="fourth">
          <div class="table_box">
            <el-table :data="tableState.tableFourth.tableData" stripe border style="width: 100%" height="600">
              <el-table-column v-for="item in tableState.tableFourth.titleData" :key="item" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableFourth.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableFourth.total"
                :page-size="tableState.tableFourth.query.pageSize"
                v-model:current-page="tableState.tableFourth.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <!-- 权重配置弹框 -->
  <weight-dialog :show="weightState.show" @close="weightClose" @reDefualt="reDefualt"
    @setStorageConfig='setStorageConfig'></weight-dialog>
</template> 

<script setup>
import MyHeader from "./MyHeader.vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { reactive } from "vue";
import { getApiExport } from "@/request/api";
import outLoginHook from "@/utils/outLoginHook.js";
import paramsListHook from "./paramsListHook.js";
import { ElMessage } from "element-plus";
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";
import { Right } from "@element-plus/icons-vue";
import tableStyleHook from "./tableStyleHook.js";
import menuChangeHook from "./menuChangeHook.js";
import WeightDialog from "./WeightDialog.vue";
import useWeight from "./useWeight.js";
import useTableData from './useTableData';

const state = reactive({
  loading: false,
  menuList: [
    { id: 1, name: "自定义搜索" },
    // { id: 2, name: "完全创新型(单店单菜)" },
    // { id: 3, name: "完全创新型(连锁品牌)" },
    // { id: 4, name: "区域创新型" },
    // { id: 5, name: "老品爆发型" },
  ],
  menuActive: 1,//顶部菜单选择
  tableActive: 'first',//table选择项
  query: {
    // 数据范围筛选
    batch: "", //批次
    province: [],//省份
    city: [],//城市
    ratioType: "环比", //比率
    mainType: [], //餐饮类型
    cuisineType: [],//菜系类型
    cuisine: [],//菜系
    materialMainType: [],//原料大类
    materialType: [],//原料二级类型
    materialSubType: [],//原料细类
    flavorMainType: [],//风味大类
    flavorType: [],//风味二级类型
    processMainType: [],//制作工艺
    styleType: [], //奉客形式
    shop_num_min: null,// 品牌门店数
    shop_num_max: null,
    // 计算结果筛选
    brand_num_min: null, //品牌数
    brand_num_max: null,
    province_num_min: null, //省份数
    province_num_max: null,
    city_num_min: null, //城市数
    city_num_max: null,
    brand_ratio_min: null, //品牌增长率
    brand_ratio_max: null,
    hot_score_min: null, //热度得分
    hot_score_max: null,
    dish_recommeds_rel_ratio_min: null, //总推荐数增长率
    dish_recommeds_rel_ratio_max: null,
    avg_recommeds_rel_ratio_min: null, //店均推荐数增长率
    avg_recommeds_rel_ratio_max: null,
    dish_recommends_num_min: null, //总推荐数
    dish_recommends_num_max: null,
    avg_recommends_num_min: null, //店均推荐数
    avg_recommends_num_max: null,
    prov_ratio_min: null,//省份增长率
    prov_ratio_max: null,
    city_ratio_min: null, //城市增长率
    city_ratio_max: null,
  }
});

// 权重配置
const { state: weightState, open: weightOpen, close: weightClose, reDefualt, setStorageConfig, getWeightConfig } = useWeight()

// 重置
function resetFunc() {
  // 重置条件
  menuChange(state.menuActive);
  // 清空表格
  clearTable()
}

// 获取列表
function getList() {
  switch (state.tableActive) {
    case 'first':
      getListFirst()
      break;
    case 'second':
      getListSecond()
      break;
    case 'third':
      getListThird()
      break;
    case 'fourth':
      getListFourth()
      break;
  }
}

// 表格数据
const { state: tableState, search, getListFirst, getListSecond, getListThird, getListFourth, pageChange, clearTable } = useTableData(state, getList, getWeightConfig)

// 导出
function exportExcel() {
  let tableData = [];
  switch (state.tableActive) {
    case 'first':
      tableData = tableState.tableFisrt.tableData || [];
      break;
    case 'second':
      tableData = tableState.tableSecond.tableData || [];
      break;
    case 'third':
      tableData = tableState.tableThird.tableData || [];
      break;
    case 'fourth':
      tableData = tableState.tableFourth.tableData || [];
      break;
  }
  if (tableData.length === 0) {
    ElMessage.error("暂无数据，请搜索");
    return;
  }
  const token = getStorage("token");
  const href = `${baseURL}${getApiExport()}?token=${token}&code=${state.tableActive}&batch=${state.query.batch}`;
  const a = document.createElement("a");
  a.href = href;
  a.download = href;
  a.click();
}

// 顶部菜单切换
const { menuChange } = menuChangeHook(state);

// 退出登录
const { outLoginFun } = outLoginHook();

// 条件选项
const { paramsListState, changeProvince, changeMainType, changeCuisineType, changeMaterialMainType, changeMaterialType, changeflavor } = paramsListHook(state);

// table样式
const { headerCellStyleHandle, cellStyleHandle } = tableStyleHook();

</script>

<style lang="scss" scoped>
.home {
  padding: 40px;
  padding-top: 80px;

  .row_box {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 10px;

    .tit_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 20px;

      .li {
        margin-right: 20px;
        margin-bottom: 10px;

        h6 {
          font-weight: normal;
          color: #818a9b;
          padding-bottom: 6px;
          font-size: 14px;
        }

        .el-select {
          width: 200px;
        }

        :deep(.el-input__inner) {
          border-radius: 7px;
        }

        .int_box {
          display: flex;
          align-items: center;

          .el-input {
            width: 98px;
          }
        }
      }
    }

    .btn_box {
      text-align: center;
      padding-top: 10px;
    }

    .table_box {
      position: relative;

      .no_data {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 180px;
      }
    }
  }

  .page_box {
    text-align: right;
    padding: 20px 0;
  }
}

:deep(.el-table__empty-text) {
  display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
